import * as React from "react"
import { useState, useEffect } from "react"
import { Link } from "gatsby"
import LeftArrow from "../../svg/left-arrow.svg"
import RightArrow from "../../svg/right-arrow.svg"

import Layout from "../../components/layout"

import "./index.scss"

const IndexPage = ({ pageContext }) => {
  const [data, setData] = useState(pageContext.content.sort(function(a, b) {
    return a.node.order - b.node.order
  } ))
  console.log(pageContext.content)
  const [activeItem, setActiveItem] = useState(0)

  function goLeft() {
    if (activeItem >= 1) {
      setActiveItem(activeItem - 1)
    }
  }

  function goRight() {
    if (activeItem <= data.length - 1) {
      setActiveItem(activeItem + 1)
    }
  }

  return (
    <Layout pageclass="index">
      <button
        className={`landing__arrow-btn landing__arrow-btn--left ${activeItem < 1 && 'hidden'}`}
        onClick={goLeft}
      >
        <img data-type="icon" src={LeftArrow} />
      </button>
      <button
        className={`landing__arrow-btn landing__arrow-btn--right ${activeItem >= data.length - 1 && 'hidden'}`}
        onClick={goRight}
      >
        <img data-type="icon" src={RightArrow} />
      </button>

      <LandingPage />

      <div className={`inner grid-parent`}>
        <div
          className="inner__inner"
          style={{ transform: `translateX(${-activeItem * 66}vw)` }}
        >
          {data.map(item => (
            <Link
              className="container"
              to={`${
                item.node.link.startsWith("http")
                  ? item.node.link
                  : `${item.node.link}`
              }`}
              target={item.node.link.startsWith("http") && "__blank"}
            >
              <div
                className="image"
                style={{
                  backgroundImage: `url(${item.node.featuredImage.file.url})`,
                }}
              ></div>
              <div className="title">{item.node.title}</div>
              <div>{item.node.date}</div>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  )
}

const LandingPage = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isTextVisible, setIsTextVisible] = useState(false)
  const [isRemoved, setIsRemoved] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true)
    }, 2000)

    setTimeout(() => {
      setIsRemoved(true)
    }, 2400)

    setTimeout(() => {
      setIsTextVisible(false)
    }, 1600)

    setTimeout(() => {
      setIsTextVisible(true)
    }, 0)
  }, [])

  return (
    <div
      className={`landing ${isLoaded ? "isLoaded" : ""} ${
        isRemoved ? "isRemoved" : ""
      }`}
    >
      <div className="landingTitleContainer">
        <div
          className={`landingTitle ${
            isTextVisible ? "landingTitleVisible" : ""
          }`}
        >
          RAINRAIN
        </div>
      </div>
    </div>
  )
}

export default IndexPage
